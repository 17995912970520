.tiptap {
  border-radius: 14px;
  background: #fff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.25);
}
.bubble_menu {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.3);

  button {
    border-radius: 8px;
    padding: 6px 8px;
    margin-right: 8px;
    cursor: pointer;
    transition: background-color 200ms ease-in-out;

    &:last-child {
      margin-right: 0px;
    }

    &:hover {
      background-color: #f1f1f1;
    }

    &.active {
      color: #334bfa;
      background-color: #eef0fc;
    }
  }
}
.fixed_menu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 8px;
  border-radius: 14px 14px 0px 0px;
  border-bottom: 1px solid var(--gray-200, #e5e7eb);
  background: #fff;

  button {
    border-radius: 8px;
    padding: 6px 8px;
    margin-right: 6px;
    cursor: pointer;
    transition: background-color 200ms ease-in-out;
    color: #6b7280;

    &:last-child {
      margin-right: 0px;
    }

    &:hover {
      background-color: #f1f1f1;
      color: black;
    }

    &.active {
      color: #334bfa;
      background-color: #eef0fc;
    }
  }
}
.tiptap_editor_content {
  & > div {
    padding: 20px 62px 20px 15px;
    width: 100%;
    height: 103px;
    max-height: 375px;
    overflow: auto;
    transition: height 0.2s ease;

    &:focus-visible {
      outline: none;
    }
  }
}
.footer {
  border-radius: 0px 0px 14px 14px;
}

.markdown_editor_content {
  & > div {
    padding: 8px 12px;
    width: 100%;
    height: 320px;
    overflow: auto;

    &:focus-visible {
      outline: none;
    }
  }
}

.markdown_editor_content_sm {
  & > div {
    height: 130px !important;
  }
}
