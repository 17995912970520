.container {
  --hoverBgColor: #ededed;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 11px 16px 11px 16px;
  gap: 8px;
  align-self: center;
  cursor: pointer;
  isolation: isolate;
  background: #f3f4f6;
  border-radius: 12px;
  flex: none;
  order: 0;
  flex-grow: 0;
  position: relative;
  z-index: 10;

  &:hover,
  &.isOpen {
    background-color: var(--hoverBgColor);
  }

  .placeholder {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #4b5563;
    white-space: nowrap;
  }

  .dropdownBox {
    position: absolute;
    left: 0;
    top: calc(100% + 5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px 0px;

    width: calc(100% - 2px);
    height: 248px;
    background: #ffffff;
    box-shadow:
      0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -2px rgba(0, 0, 0, 0.05),
      0px 0px 0px 1px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 0.5em;
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin: 0.625em 0;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(230, 230, 230, 0.928);
      border-radius: 0.1875em;
      border: 0.125em solid transparent;
      background-clip: padding-box;
    }

    .item {
      @apply transition-all;

      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 12px 8px 12px;
      min-height: 40px;
      width: 100%;

      &.selected {
        background-color: #f3f4f6;
      }

      &:hover {
        background-color: var(--hoverBgColor);
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  span {
    font-family: var(--font-inter);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
}
