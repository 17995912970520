.container {
  display: flex;
  gap: 28px;
  padding-left: 1rem;
  padding-right: 2rem;

  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .addButton {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    gap: 8px;
    border: 1px solid #000000;
    border-radius: 12px;
    flex: none;
    flex-grow: 0;
    margin-bottom: auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    transition: all 0.1s ease-in;
    &:hover {
      background-color: #111827;

      &,
      * {
        color: white;
      }
    }
  }
}
