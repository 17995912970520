.tiptap_editor_content {
  border-width: 1px;
  border-radius: 6px;

  & > div {
    width: 100%;
    min-height: 160px;
    max-height: 240px;
    border-radius: 6px;
    padding: 8px 12px 8px 12px;
    overflow: auto;
  }
}
