.container {
  @apply mx-5 my-auto flex h-full w-[calc(100%_-_2.50rem)] flex-col items-center;

  * {
    font-family: var(--font-poppins) !important;
  }

  .resultContainerWrapper {
    @apply relative w-full max-w-[1124px];
  }

  .result {
    @apply relative max-h-[500px] overflow-y-scroll rounded-lg border border-gray-200 bg-gray-50 p-5;

    &::-webkit-scrollbar {
      width: 8px;
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin: 60px 0;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(227, 227, 227, 0.611);
      border-radius: 3px;
      border: 2px solid transparent;
      background-clip: padding-box;
    }
  }

  textarea {
    &::-webkit-scrollbar {
      width: 8px;
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin: 30px 0;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(227, 227, 227, 0.611);
      border-radius: 3px;
      border: 2px solid transparent;
      background-clip: padding-box;
    }
  }

  .markdownContainer {
    line-height: 1.6;
    color: #111827;
    margin-bottom: 4rem;
    // Headings

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #111827;
      margin-top: 20px;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 18px;
      &:first-child {
        margin-top: 0;
      }
    }

    h1 {
      font-size: 20px;
      margin-bottom: 2rem;
    }
    // Paragraphs
    p:not(:first-child) {
      margin: 10px 0;
      color: #111827;
    }
    p:not(:first-child) {
      margin: 10px 0;
      color: #111827;
    }

    // Links
    a {
      color: #007bff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    // Code blocks
    pre {
      background-color: #f5f5f5;
      padding: 10px;
      border-radius: 4px;
      overflow-x: auto;

      code {
        background: none;
        color: inherit;
        padding: 0;
      }
    }

    code {
      background-color: #f5f5f5;
      padding: 2px 4px;
      border-radius: 4px;
    }

    // Lists
    ul,
    ol {
      margin: 10px 0 10px 20px;
    }

    // List items
    ul {
      list-style-type: disc;

      li {
        margin-bottom: 5px;
      }
    }

    ol {
      list-style-type: decimal;

      li {
        margin-bottom: 5px;
      }
    }

    // Blockquotes
    blockquote {
      margin: 10px 0;
      padding: 10px 20px;
      background-color: #f5f5f5;
      border-left: 5px solid #ddd;

      p {
        margin: 0;
      }
    }

    // Images
    img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 10px 0;
    }

    // Tables
    table {
      width: 100%;
      border-collapse: collapse;
      margin: 20px 0;

      th,
      td {
        padding: 12px;
        border: 1px solid #ddd;
        text-align: left;
      }

      th {
        background-color: #f2f2f2;
      }

      tr:nth-child(even) {
        background-color: #f9f9f9;
      }
    }
  }
}
