.radio {
  @apply flex items-center cursor-pointer select-none p-[6px] pl-0;
  &.disabled {
    @apply cursor-not-allowed;
  }
  input {
    @apply relative appearance-none w-[18px] h-[18px] aspect-square rounded-full border border-[#DDDDDD] focus:ring-cyan-400 text-cyan-400 transition-all duration-200 ease-in-out cursor-pointer outline-cyan-400 shadow-none;

    &::before {
      content: '';
      @apply absolute w-2 h-2 rounded-full bg-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
    }

    &:not(:disabled):not(:checked):hover {
      @apply border-cyan-400 bg-white;
    }
    &:checked {
      @apply border-cyan-400 bg-cyan-400 relative;
      &::before {
        display: block;
      }
    }

    &:not(:checked).error {
      @apply border-red-300;
      &:not(:disabled):hover {
        @apply border-red-700;
      }
    }

    &:disabled {
      @apply bg-gray-100 cursor-not-allowed bg-no-repeat bg-center bg-cover;
      border-color: rgb(210 218 227) !important;
    }
  }
  .label {
    @apply font-inter text-sm leading-[18px] text-gray-700 ml-[10px] cursor-pointer;
  }
}
