.container {
  background-color: #fff;
  max-width: 1160px;
  width: 100%;

  @media screen and (max-width: 1000px) {
    overflow-x: scroll;
  }

  &,
  * {
    user-select: none;
  }

  table {
    table-layout: fixed;
  }

  * {
    color: #111827;
  }
}

.headerRow {
  display: flex;
  justify-content: space-between;
}

.modelHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 11px 16px;
  gap: 8px;
  background: #f3f4f6;
  white-space: nowrap;

  &:hover {
    background-color: #f2f2f3;
  }

  border-radius: 12px;
  flex: none;
  order: 1;
  flex-grow: 1;

  .modelHeaderContent {
    display: flex;
    gap: 8px;
    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      margin-right: 20px;
    }
  }
}

.table {
  width: 100%;
  border-collapse: collapse;

  th {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    padding: 10px;
    width: 222px;
    padding-top: 0;
  }

  th:last-child {
    padding-right: 0px;
  }

  th:first-child {
    font-size: 24px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    width: 239px;
  }

  tr {
    td {
      padding: 10px;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      text-align: left;

      &:not(:first-child) {
        color: #374151;
      }

      &:first-child {
        font-size: 16px;
        font-weight: 500;
        width: 240px;
        padding-right: 48px;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e5e7eb;
    }
  }

  thead th {
    padding-bottom: 40px;
  }
}

.deleteButton {
  * {
    color: #dc2626;
  }

  &:hover {
    opacity: 0.8;
  }
}

.addButton {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  gap: 8px;
  border: 1px solid #000000;
  border-radius: 12px;
  flex: none;
  flex-grow: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  transition: all 0.1s ease-in;
  &:hover {
    background-color: #111827;

    &,
    * {
      color: white;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    overflow: visible;
    overflow-x: scroll;
    padding-right: 32px;
  }

  .table {
    tr {
      td {
        &:first-child {
          font-size: 16px;
          font-weight: 500;
          width: 240px;
          padding-right: 24px;
        }
      }
    }
  }
}
.heading {
  font-size: 2rem;
  font-weight: 600;
  color: #1f2937;
}
