.container {
  @apply flex w-full flex-col items-center justify-center px-4;

  * {
    font-family: var(--font-poppins) !important;
  }
}

.resultContainerWrapper {
  @apply relative w-full rounded-lg border border-gray-200 bg-gray-50 p-4;
}

.result {
  @apply max-h-[800px] min-h-[200px] whitespace-pre-wrap break-words;
  overflow-y: auto;
  transition: overflow 0.2s ease;
}

.resultVisible {
  overflow: visible !important;
}

.markdownContainer {
  display: inline;
  position: relative;
  white-space: normal;
  font-size: 14px;

  div,
  p {
    display: contents;
  }

  :global(.correctedUsage) {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 100;
    background: white;
    padding: 12px 16px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    min-width: 200px;
    border: 1px solid #e0e0e0;
    margin-top: 8px;
    font-size: 0.95em;
    color: #198754;

    &::before {
      content: '';
      position: absolute;
      top: -5px;
      left: 10px;
      width: 10px;
      height: 10px;
      background: white;
      transform: rotate(45deg);
      border-left: 1px solid #e0e0e0;
      border-top: 1px solid #e0e0e0;
    }

    :global(.applyButton) {
      margin-left: 12px;
      padding: 6px 12px;
      background-color: #198754;
      color: white;
      border: none;
      border-radius: 6px;
      cursor: pointer;
      font-size: 13px;
      font-weight: 500;
      display: inline-flex;
      align-items: center;
      transition: all 0.2s ease;
      box-shadow: 0 2px 4px rgba(25, 135, 84, 0.2);

      &:hover {
        background-color: #157347;
        box-shadow: 0 2px 6px rgba(25, 135, 84, 0.3);
        transform: translateY(-1px);
      }

      &:active {
        transform: translateY(0);
        box-shadow: 0 1px 2px rgba(25, 135, 84, 0.2);
      }
    }
  }

  :global(.grammarFix) {
    display: inline;
    position: relative;

  

    &.showCorrection {
      :global(.correctedUsage) {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        animation: fadeIn 0.2s ease-out;
        gap: 8px;

        small {
          color: rgb(101, 101, 101);
          font-style: italic;
        }
        button {
          margin-left: auto;
        }
      }
    }
  }

  :global(.inCorrectedUsage) {
    text-decoration: line-through;
    cursor: pointer;


    &:global(.spelling) {
      color: #dc3545;
      &:hover {
        background-color: rgba(220, 53, 69, 0.1);
      }
    }

    &:global(.grammar) {
      color: #ffc107;
      &:hover {
        background-color: rgba(255, 193, 7, 0.1);
      }
    }

    &:global(.punctuation) {
      color: #17a2b8;
      &:hover {
        background-color: rgba(23, 162, 184, 0.1);
      }
    }

    &:global(.pronoun) {
      color: #28a745;
      &:hover {
        background-color: rgba(40, 167, 69, 0.1);
      }
    }

    &:global(.confusedWord) {
      color: #6f42c1;
      &:hover {
        background-color: rgba(111, 66, 193, 0.1);
      }
    }

  }

}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.correctionTypes {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.correctionBadge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.spelling {
  background-color: rgba(220, 53, 69, 0.1);
  color: #dc3545;
}

.grammar {
  background-color: rgba(255, 193, 7, 0.1);
  color: #ffc107;
}

.punctuation {
  background-color: rgba(23, 162, 184, 0.1);
  color: #17a2b8;
}

.pronoun {
  background-color: rgba(40, 167, 69, 0.1);
  color: #28a745;
}

.confusedWord {
  background-color: rgba(111, 66, 193, 0.1);
  color: #6f42c1;
}
