.chat_card {
  @apply relative flex w-full items-center justify-start px-[18px] py-[18px] transition-colors;
  .chat_card_icon {
    @apply absolute right-[5px] top-1/2 -translate-y-1/2 opacity-0 transition-opacity;
  }

  &:hover {
    background: linear-gradient(
      270deg,
      #f3f3f3 0%,
      rgba(243, 243, 243, 0) 100%
    );

    .chat_card_icon {
      @apply opacity-100;
    }
  }
}
