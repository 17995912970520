.container {
  @apply h-full w-[calc(100%_-_2.50rem)] mx-5;
  @apply relative;

  .markdownContainer {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 20px 0;

    // Headings
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #222;
      margin-top: 20px;
      margin-bottom: 10px;
      font-weight: 600;
    }

    h1 {
      font-size: 32px;
      margin-bottom: 2rem;
      font-weight: 700;
    }
    h2 {
      font-size: 28px;
      margin-top: 2rem;
      font-weight: 700;
    }
    h3 {
      font-size: 24px;
      font-weight: 600;
    }
    h4 {
      font-size: 20px;
      font-weight: 600;
    }
    h5 {
      font-size: 18px;
      font-weight: 600;
    }
    h6 {
      font-size: 16px;
      font-weight: 600;
    }

    hr {
      display: none;
    }

    // Paragraphs
    p {
      margin: 10px 0;
      color: #303030;
    }

    // Links
    a {
      color: #007bff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    // Code blocks
    pre {
      background-color: #f5f5f5;
      padding: 10px;
      border-radius: 4px;
      overflow-x: auto;

      code {
        background: none;
        color: inherit;
        padding: 0;
      }
    }

    code {
      background-color: #f5f5f5;
      padding: 2px 4px;
      border-radius: 4px;
    }

    // Lists
    ul,
    ol {
      margin: 10px 0 10px 20px;
    }

    // List items
    ul {
      list-style-type: disc;

      li {
        margin-bottom: 5px;
      }
    }

    ol {
      list-style-type: decimal;

      li {
        margin-bottom: 5px;
      }
    }

    // Blockquotes
    blockquote {
      margin: 10px 0;
      padding: 10px 20px;
      background-color: #f5f5f5;
      border-left: 5px solid #ddd;

      p {
        margin: 0;
      }
    }

    // Images
    img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 10px 0;
    }

    // Tables
    table {
      width: 100%;
      border-collapse: collapse;
      margin: 20px 0;

      th,
      td {
        padding: 12px;
        border: 1px solid #ddd;
        text-align: left;
      }

      th {
        background-color: #f2f2f2;
      }

      tr:nth-child(even) {
        background-color: #f9f9f9;
      }
    }
  }
}
