.container {
  *{
    font-family: var(--font-poppins)!important;
  }
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  opacity: 1;
  visibility: visible;

  &:not(.loaded) {
    display: none;
  }

  &.loaded {
    display: block;
  }
}

.correctChar {
  @apply text-green-400 transition-all duration-200;
}

.incorrectChar {
  color: #ef4444;
}

.correctWord {
  @apply rounded transition-all duration-200;
}

.stats {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
}

.statItem {
  text-align: center;
  @apply rounded-lg border border-gray-200 p-4;
}

.statValue {
  font-size: 1.5rem;
  font-weight: bold;
}

.statLabel {
  font-size: 0.875rem;
  opacity: 0.8;
}

.wordArea {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  background: #f5f5f5;
  margin: 20px 0;

  @apply rounded-lg border border-gray-200;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 150px;
    pointer-events: none;
    z-index: 1;
  }

  &::before {
    left: 0;
    background: linear-gradient(to right, #f5f5f5 0%, transparent 100%);
  }

  &::after {
    right: 0;
    background: linear-gradient(to left, #f5f5f5 0%, transparent 100%);
  }
}

.wordWrapper {
  position: relative;
  display: flex;
  gap: 8px;
  transform: translateX(calc(50% - var(--current-word-offset)));
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
}

.word {
  display: inline-block;
  padding: 0 4px;
  font-size: 1.5rem;
  white-space: nowrap;

  &.incorrect {
    color: #ef4444;
    text-decoration: line-through;
    opacity: 0.8;
  }

  &.correct {
    color: #22c55e;
  }
}

.current {
  color: #000;
}

.input {
  width: 100%;
  padding: 1rem;
  font-size: 1.1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 1rem;

  &:focus {
    outline: none;
    border-color: #2196f3;
  }
}

.resetButton {
  padding: 0.5rem 1rem;
  background: #2196f3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #1976d2;
  }
}

.statsArea {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin: 1.5rem 0;
}

.statBox {
  background: #f8f9fa;
  padding: 1.2rem;
  border-radius: 12px;
  text-align: center;
  transition: transform 0.2s;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-2px);
  }
}

.statDescription {
  font-size: 0.8rem;
  color: #718096;
  line-height: 1.2;
}

.modalFooter {
  padding: 1rem 1.5rem;
  border-top: 1px solid #edf2f7;
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
}

.button {
  padding: 0.6rem 1.2rem;
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.9rem;
  transition: all 0.2s;
  border: none;
  cursor: pointer;

  &:hover {
    transform: translateY(-1px);
  }
}

.primaryButton {
  background: #4285f4;
  color: white;

  &:hover {
    background: darken(#4285f4, 5%);
  }
}

.secondaryButton {
  background: #f1f3f5;
  color: #4a5568;

  &:hover {
    background: darken(#f1f3f5, 5%);
  }
}

.chartLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  color: #718096;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Media query for mobile responsiveness
@media (max-width: 768px) {
  .modalContent {
    flex-direction: column;
  }

  .chartSection,
  .resultsSection {
    padding: 1.5rem;
  }

  .statsGrid {
    grid-template-columns: 1fr;
  }

  .statValue {
    font-size: 2rem;
  }

  .actionButtons {
    flex-direction: column;
  }
}

.cursor {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: -2px;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: #000;
    animation: blink 1s infinite;
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.flexRow {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}

.chartArea {
  flex: 1;
  min-width: 300px;
}

.statsArea {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.completionMessage {
  text-align: center;
  margin-top: 2rem;

  h3 {
    margin-bottom: 1rem;
  }
}
