.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  isolation: isolate;
  overflow: hidden;
  height: 460px;
  background: #ffffff;
  position: relative;

  * {
    user-select: none;
  }

  box-shadow:
    0px 24px 24px -12px rgba(20, 20, 20, 0.06),
    0px 5px 20px rgba(20, 20, 20, 0.04),
    0px 6px 6px -3px rgba(20, 20, 20, 0.05),
    0px 3px 3px -1.5px rgba(20, 20, 20, 0.05),
    0px 1px 1px -0.5px rgba(20, 20, 20, 0.05),
    0px 0px 0px 1px rgba(20, 20, 20, 0.06);
  border-radius: 12px;

  .chatbotHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    background: #f3f4f6;
    white-space: nowrap;
    border-bottom: 1px solid #0000000d;

    &:hover {
      background-color: #f2f2f3;
    }

    border-radius: 12px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    flex: none;

    .chatbotHeaderContent {
      display: flex;
      gap: 8px;
      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        margin-right: 20px;
      }
    }
  }
  .chatbotBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 15px 12px;
    gap: 20px;
    width: 100%;
    height: 329px;
    background: #ffffff;
    flex: none;
    padding-bottom: 40px;

    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 0.5em;
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin: 0.625em 0;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(230, 230, 230, 0.928);
      border-radius: 0.1875em;
      border: 0.125em solid transparent;
      background-clip: padding-box;
    }

    .message {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      padding: 0px;
      gap: 15px;
      flex: none;
      align-self: stretch;
      flex-grow: 0;

      .box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        flex: none;
        flex: 1;
        flex-grow: 1;
        width: 100%;
        font-family: var(--font-inter);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        padding: 15px 22px 15px 18px;
        gap: 10px;
        border-radius: 15px;
        background-color: #f2f3f4;
      }

      transform-origin: left bottom;

      &.user {
        transform-origin: right bottom;

        margin-left: auto;
        .box {
          background: #0c0c0c;
          color: white;
          justify-content: flex-start;
        }
      }
    }

    .sending {
      position: absolute;
      bottom: 84px;
      left: 0;
      padding-bottom: 7px;
      padding-top: 3px;
      padding-left: 15px;
      pointer-events: none;
      width: 100%;
      background: linear-gradient(to right, white, transparent);
      backdrop-filter: blur(1px);

      .inner {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        span {
          font-size: 14px;
          color: rgb(49, 49, 49);
        }
      }
    }
  }
  .chatbotSendMessageBox {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 15px;
    padding-left: 0;
    width: 100%;
    height: 60px;
    background: #ffffff;
    border-width: 1px 0px 1px 0px;
    border-style: solid;
    border-color: #eaeaea;
    flex: none;
    align-self: stretch;

    input {
      padding: 19px 15px 19px 15px;
      font-family: var(--font-inter);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;

      width: 100%;
      background-color: #ffffff;
      border: none;
      appearance: none;
      &:focus,
      &:focus-within {
        outline: none;
        border: none;
        box-shadow: none;
      }

      &::placeholder {
        color: #a3a3a3;
      }
      &:disabled {
        cursor: wait;
      }
    }
    button {
      color: #777777;
      transition: all 0.15s ease;

      &:not(:disabled):hover {
        color: black;
      }

      &:disabled {
        cursor: wait;
      }
    }
  }

  .poweredByContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.4375em 0.625em;
    gap: 0.625em;
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    align-self: stretch;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
    color: #737373;

    transition: all 0.3 ease-in;

    &:hover {
      text-decoration: underline;
      color: #424242;
    }
  }
}

@keyframes messageIn {
  0% {
    opacity: 0;
    scale: 0;
  }
  24% {
    opacity: 0.01;
    scale: 0.8;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}

.messageInAnimation {
  animation: 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.1) messageIn forwards;
}
