.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255 255 255 / 50%);
  display: flex;
  justify-content: center;
  backdrop-filter: blur(2px);
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  border-radius: 12px;
  padding: 40px;
  min-width: 320px;
  max-width: 480px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #6b6b6b29;
}

.modalHeader {
  text-align: center;
  margin-bottom: 6px;

  h2 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
  }
}
.animation {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.modalBody {
  margin-bottom: 24px;
}

.resultItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }

  .label {
    color: #666;
    font-size: 16px;
  }

  .value {
    font-size: 18px;
    font-weight: 600;
    color: #333;
  }
}

.modalFooter {
  display: flex;
  justify-content: center;
}

.levelHeader {
  text-align: center;
  margin-bottom: 24px;

  h3 {
    font-size: 24px;
    margin-bottom: 8px;
    color: #2c7be5;
    font-weight: 600;
  }

  p {
    color: #666;
    margin: 0;
  }
}
